import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import { toast } from "react-toastify";
import minus from "../assets/svgs/minus.svg";
import add from "../assets/svgs/add.svg";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";

const MasterItems = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [issueItems, setIssueItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterValue, setFilterValue] = useState("");
  const rowsPerPage = 5;

  const [data, setData] = useState({
    result: null,
    value: "",
  });
  const [openModal, setOpenModal] = useState(false);

  const handleGoodsIssuesModal = async (data) => {
    setData((prev) => ({
      ...prev,
      result: data,
      value: "goods",
    }));
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const updateQuantity = (id, increment) => {
    setIssueItems((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        if (item.id === id) {
          const newQuantity = item.userQuantity + increment;

          // Ensure the quantity stays within bounds
          if (newQuantity < 0 || newQuantity > item.availableQuantity) {
            return item; // Return unchanged if out of bounds
          }

          return { ...item, userQuantity: newQuantity };
        }
        return item;
      });

      localStorage.setItem("issueItems", JSON.stringify(updatedItems)); // Save updated items
      return updatedItems;
    });
  };

  const filteredList = filterValue
    ? items.filter(
        (item) =>
          (item.itemCode &&
            item.itemCode.toLowerCase().includes(filterValue.toLowerCase())) ||
          (item.itemName &&
            item.itemName.toLowerCase().includes(filterValue.toLowerCase()))
      )
    : items;

  const paginatedItems = filteredList?.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const totalPages = Math.ceil(filteredList.length / rowsPerPage);
  const handleFilterChange = (value) => {
    setFilterValue(value); // Update filter
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getMasterItems();
  }, []);

  const searchData = async (dataQuery) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/api/Masters/GET_ItemAgainstBarCode?BarCode=${dataQuery}`
      );
      if (response?.data?.message === "success") {
        const itemsWithQuantity = response.data.data.map((item) => ({
          ...item,
          userQuantity: 0,
          id: Math.round(Math.random() * 100),
        }));
        setIssueItems(itemsWithQuantity);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response?.data?.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  async function getMasterItems() {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/api/Masters/Get_ItemsMaster");
      if (response.status === 200) {
        setItems(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setLoading(false);
      toast.error(error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleRowClick = (item) => {
    setSelectedItem(item);
    searchData(item?.result?.itemCode); // Search based on the selected row's itemCode
  };

  return (
    <div className="">
      <div className=" bg-tertiary-grey-50  w-full flex justify-center items-center">
        <div className="lg:w-1/2 md:w-1/2 w-full lg:shadow-new md:shadow-new  lg:p-10 md:p-10 p-4  mb-6 lg:bg-white md:bg-white bg-tertiary-grey-50 lg:rounded-lg md:rounded-lg rounded-none">
          {!selectedItem?.result?.itemCode ? (
            <>
              <div className="space-y-8">
                <div>
                  <input
                    type="text"
                    name="search"
                    className="w-full px-4 py-2 bg-tertiary-grey-40 rounded-lg outline-none font-poppins font-normal text-sm leading-5 text-tertiary-grey-700 placeholder:font-poppins placeholder:font-normal placeholder:text-tertiary-grey-700 placeholder:leading-5 placeholder:text-sm disabled:cursor-not-allowed"
                    placeholder="Search item "
                    value={filterValue}
                    onChange={(e) => handleFilterChange(e.target.value)}
                    disabled={loading}
                  />
                </div>
                {!selectedItem?.result?.itemCode && (
                  <div className="pb-4">
                    <button
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                      className={`px-3 py-1 rounded  bg-primary text-white`}
                    >
                      Back
                    </button>
                  </div>
                )}
                {loading ? (
                  <div className="w-full bg-white rounded-lg shadow-new py-2 px-3 flex justify-center items-center">
                    <svg
                      className="w-8 h-8 text-gray-300 animate-spin"
                      viewBox="0 0 64 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                        stroke="currentColor"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                        stroke="currentColor"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="text-primary"
                      ></path>
                    </svg>
                  </div>
                ) : (
                  <>
                    {paginatedItems?.length === 0 ? (
                      <></>
                    ) : (
                      <>
                        <div className="w-full overflow-x-auto bg-white rounded-lg shadow-new">
                          <table className="min-w-full">
                            <thead>
                              <tr className="text-left">
                                <th className="p-3 text-sm text-gray-500 text-nowrap">
                                  Item Code
                                </th>
                                <th className="p-3 text-sm text-gray-500 text-nowrap">
                                  Item Name
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {paginatedItems?.map((result, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className={`border-t border-gray-200 text-left cursor-pointer ${
                                      selectedItem?.index === index
                                    } ? 'bg-gray-200': ''`}
                                    onClick={() =>
                                      handleRowClick({ result, index })
                                    }
                                  >
                                    <td className="p-3">
                                      <p className="text-tertiary-grey-700 font-poppins font-normal text-xs leading-4">
                                        {result.itemCode}
                                      </p>
                                    </td>
                                    <td className="p-3">
                                      <p className="text-tertiary-grey-700 font-poppins font-normal text-xs leading-4">
                                        {result.itemName}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="flex gap-4 justify-end items-center mt-4">
                {/* Previous Button */}
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`px-3 py-1 rounded ${
                    currentPage === 1
                      ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                      : "bg-primary text-white"
                  }`}
                >
                  Previous
                </button>

                {/* Next Button */}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`px-3 py-1 rounded ${
                    currentPage === totalPages
                      ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                      : "bg-primary text-white"
                  }`}
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="space-y-8">
                <>
                  {/* <Search searchData={searchData} type="goods" /> */}
                  <div className="flex flex-row justify-start items-center gap-4">
                    <button
                      className="p-2 bg-primary text-white flex justify-center items-center rounded-lg"
                      onClick={() => {
                        setSelectedItem(null);
                        localStorage.removeItem("issuesItems");
                        localStorage.removeItem("query");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </>
                {loading ? (
                  <div className="w-full bg-white rounded-lg shadow-new py-2 px-3 flex justify-center items-center">
                    <svg
                      className="w-8 h-8 text-gray-300 animate-spin"
                      viewBox="0 0 64 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                        stroke="currentColor"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                        stroke="currentColor"
                        strokeWidth="5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="text-primary"
                      ></path>
                    </svg>
                  </div>
                ) : (
                  <>
                    {issueItems?.length === 0 ? (
                      <>
                        <p>No Data Found</p>
                      </>
                    ) : (
                      <>
                        <div className="bg-white rounded-lg shadow-new py-4 px-6 mb-4">
                          <h2 className="text-lg font-semibold text-primary">
                            {issueItems[0]?.itemName}
                          </h2>
                          <p className="text-sm text-tertiary-grey-700">
                            Item Code: {issueItems[0]?.itemCode}
                          </p>
                        </div>
                        <div className="w-full overflow-x-auto bg-white rounded-lg shadow-new">
                          <table className="min-w-full">
                            <thead>
                              <tr className="text-left">
                                <th className="p-3 text-sm text-gray-500 text-nowrap">
                                  Warehouse Code
                                </th>
                                <th className="p-3 text-sm text-gray-500 text-nowrap">
                                  Warehouse Name
                                </th>
                                <th className="p-3 text-sm text-gray-500 text-nowrap">
                                  Available Quantity
                                </th>
                                <th className="p-3 text-sm text-gray-500">
                                  Quantity
                                </th>
                                <th className="p-3 text-sm text-gray-500 text-nowrap">
                                  Bin Location
                                </th>
                                <th className="p-3 text-sm text-gray-500">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {issueItems?.map((result, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className="border-t border-gray-200 text-center"
                                  >
                                    <td className="p-3">
                                      <p className="text-tertiary-grey-700 font-poppins font-normal text-xs leading-4">
                                        {result.warehouseCode}
                                      </p>
                                    </td>
                                    <td className="p-3">
                                      <p className="text-tertiary-grey-700 font-poppins font-normal text-xs leading-4">
                                        {result.warehouseName}
                                      </p>
                                    </td>
                                    <td className="text-tertiary-grey-700 font-poppins font-normal text-xs leading-4">
                                      {result.quantityAvailable}
                                    </td>
                                    <td className="p-3">
                                      <div className="flex items-center gap-2">
                                        <button
                                          onClick={() =>
                                            updateQuantity(result.id, -1)
                                          }
                                          className={`bg-tertiary-grey-40 flex justify-center items-center rounded-md px-2 py-3 
                                          ${
                                            result.userQuantity <= 0
                                              ? "bg-gray-200 opacity-50 text-gray-400 cursor-not-allowed"
                                              : "bg-tertiary-grey-40 text-gray-700 hover:bg-tertiary-grey-60 cursor-pointer"
                                          }
                                          `}
                                          disabled={result.userQuantity <= 0}
                                        >
                                          <ReactSVG src={minus} />
                                        </button>
                                        {/* <span className="text-tertiary-grey-700 font-poppins font-normal text-xs leading-4">
                                        {result.userQuantity}
                                      </span> */}

                                        <input
                                          type="number"
                                          value={result.userQuantity}
                                          onChange={(e) => {
                                            const value =
                                              e.target.value === ""
                                                ? 0
                                                : parseInt(e.target.value, 10); // Treat empty input as 0

                                            // Ensure the value is within bounds (0 to quantityAvailable)
                                            if (
                                              value >= 0 &&
                                              value <= result.quantityAvailable
                                            ) {
                                              const diff =
                                                value - result.userQuantity; // Calculate the difference between the new value and the current value
                                              updateQuantity(result.id, diff); // Update the quantity based on the difference
                                            }
                                          }}
                                          className="w-16 text-center text-tertiary-grey-700 font-poppins font-normal text-xs leading-4 border border-tertiary-grey-300 rounded-md"
                                          min="0"
                                          max={result.quantityAvailable}
                                        />

                                        <button
                                          onClick={() =>
                                            updateQuantity(result.id, 1)
                                          }
                                          className={`bg-tertiary-grey-40 flex justify-center items-center rounded-md px-2 py-2 
                                          ${
                                            result.userQuantity ==
                                            result.quantityAvailable
                                              ? "bg-gray-200 opacity-50 text-gray-400 cursor-not-allowed"
                                              : "bg-tertiary-grey-40 text-gray-700 hover:bg-tertiary-grey-60 cursor-pointer"
                                          }
                                          `}
                                          disabled={
                                            result.quantityAvailable ==
                                            result.userQuantity
                                          }
                                        >
                                          <ReactSVG src={add} />
                                        </button>
                                      </div>
                                    </td>
                                    <td className="py-3 px-4">
                                      <p className="text-tertiary-grey-700 font-poppins font-normal text-xs leading-4">
                                        {result?.binLocation
                                          ? result?.binLocation
                                          : "null"}
                                      </p>
                                    </td>
                                    <td className="py-3 px-4">
                                      <div className="">
                                        <button
                                          onClick={() =>
                                            handleGoodsIssuesModal(result)
                                          }
                                          className={`py-1 px-2 whitespace-nowrap font-poppins font-medium text-xs leading-5 text-white bg-primary rounded-lg w-full
                                           ${
                                             result?.userQuantity === 0
                                               ? "bg-gray-200 text-gray-400 cursor-not-allowed opacity-40"
                                               : ""
                                           }
                                          `}
                                          disabled={result?.userQuantity === 0}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
        {openModal && <Modal closeModal={closeModal} data={data} />}
      </div>
    </div>
  );
};

export default MasterItems;
