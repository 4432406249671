import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../layout/Layout";
import PageLoading from "../components/fallback/PageLoading";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "../pages/NotFound";
import PostDoc from "../pages/PostDoc";
import MasterItems from "../pages/MasterItems";
const ItemReport = lazy(() => import("../pages/ItemReport"));
const GoodsIssuesPage = lazy(() => import("../pages/GoodsIssuesPage"));
const GoodsReceiptsPage = lazy(() => import("../pages/GoodsReceiptsPage"));
const Login = lazy(() => import("../pages/Login"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const PostDocHistory = lazy(() => import("../pages/PostDocHistory"));

function Routing() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<PageLoading />}>
            {" "}
            <Login />
          </Suspense>
        }
      />
      <Route path="/" element={<Layout />}>
        <Route element={<ProtectedRoute />}>
          <Route
            path="/dashboard"
            element={
              <Suspense fallback={<PageLoading />}>
                <Dashboard />
              </Suspense>
            }
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path="/goodsIssues"
            element={
              <Suspense fallback={<PageLoading />}>
                <GoodsIssuesPage />
              </Suspense>
            }
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path="/masterItems"
            element={
              <Suspense fallback={<PageLoading />}>
                <MasterItems />
              </Suspense>
            }
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path="/goodsReceipt"
            element={
              <Suspense fallback={<PageLoading />}>
                <GoodsReceiptsPage />
              </Suspense>
            }
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path="/goods"
            element={
              <Suspense fallback={<PageLoading />}>
                <Dashboard />
              </Suspense>
            }
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path="/itemreport"
            element={
              <Suspense fallback={<PageLoading />}>
                <ItemReport />
              </Suspense>
            }
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path="/postdoc"
            element={
              <Suspense fallback={<PageLoading />}>
                <PostDoc />
              </Suspense>
            }
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path="/postdochistory"
            element={
              <Suspense fallback={<PageLoading />}>
                <PostDocHistory />
              </Suspense>
            }
          />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Routing;
